export function pluralize(item: string, count: number) {
  return count === 1 ? item : `${item}s`;
}

export function toMoney(price: number | undefined) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price ?? 0);
}
